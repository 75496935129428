import { webComponentBaseClass } from '/third_party/web-component-base-class/dist/webComponentBaseClass.js';
import '/third_party/vicowa-web-components/dist/vicowa-icon-set/vicowa-icon-set.js';

const customElementName = 'vicowa-base-icons';
window.customElements.define(customElementName, class extends webComponentBaseClass {
	static get is() { return customElementName; }
	constructor() { super(); }

	static get template() {
		return `
		<vicowa-icon-set name="base">
			<svg slot="icons">
			<defs>
			<g id="hamburger"><path d="M2 4 h20 v3 h-20 v-3 m0 6 h20 v3 h-20 v-3 m0 6 h20 v3 h-20 v-3"></path></g>
			</defs>
			</svg>
			</vicowa-icon-set>`
		;
	}
});
